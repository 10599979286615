/*eslint max-lines: ["error", {"max": 350, "skipBlankLines": true}]*/
import React from 'react';

import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';

import {
  RootViewProvider,
  RestaurantHomeViewProvider,
  RestaurantBeaconView,
  RestaurantBeaconViewProvider,
  RestaurantCouponViewProvider,
  RestaurantCouponView,
  RestaurantCategoriesView,
  RestaurantCategoriesViewProvider,
  RestaurantCategoryViewProvider,
  RestaurantCategoryView,
  RestaurantProductViewProvider,
  RestaurantProductComboView,
  RestaurantProductComboViewProvider,
  RestaurantProductComposedViewProvider,
  RestaurantProductComposedView,
  RestaurantProductView,
  RestaurantCartView,
  RestaurantCartViewProvider,
  RestaurantOrderValidationView,
  RestaurantOrderValidationViewProvider,
  RestaurantOrderConfirmationView,
  RestaurantOrderConfirmationViewProvider,
  AdminView,
  AdminViewProvider,
  RestaurantLockView,
  RestaurantLockViewProvider,
  ReceiptView,
  ReceiptViewProvider,
  SmsView,
  SmsViewProvider,
  PaymentOptionsView,
  PaymentOptionsViewProvider,
  CardPaymentView,
  CardPaymentViewProvider,
  RefundViewProvider,
  RefundView,
  ResetViewProvider,
  ResetView,
  NotificationProvider,
  NotificationView,
  AdminAuthenticateView,
  AdminAuthenticateProvider,
  InvalidRouteView,
  InvalidRouteViewProvider,
  TransactionsView,
  TransactionsViewProvider,
  LoyaltyLoginView,
  LoyaltyLoginViewProvider,
  LoyaltyOffersView,
  LoyaltyOffersViewProvider,
} from '@fingermarkglobal/cringer.components';

import { CustomHomeView } from './src/views/home';
import { CustomDefaultTemplate } from './src/templates/default';

import { version } from './package.json';
import mockSettings from './mock-settings.json';

import logo from './src/assets/images/PizzaHutLogo.png';

const Logo = () => (
  <img
    src={logo}
    alt="Logo"
    className="absolute top-0 w-auto h-40 mt-4 mb-8 md:h-64 md:mt-32 md:mb-0"
  />
);

const Routes = () => {
  return (
    <Router>
      <RootViewProvider overwriteSettings={mockSettings} resolverProps={{ isRetryActive: true }}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/reset">
            <ResetViewProvider cancelOrder={true}>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={false}
              >
                <ResetView />
              </CustomDefaultTemplate>
            </ResetViewProvider>
          </Route>
          <Route exact path="/home">
            <RestaurantHomeViewProvider allowedLanguages={['en', 'uae']}>
              <CustomDefaultTemplate
                customBackgroundImage={'bg-home'}
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={false}
                showAccessibilityButton={true}
              >
                <CustomHomeView />
              </CustomDefaultTemplate>
            </RestaurantHomeViewProvider>
          </Route>
          <Route exact path="/beacon">
            <RestaurantBeaconViewProvider primaryPath="/categories" secondaryPath="/reset">
              <CustomDefaultTemplate headerEnabled={false}>
                <RestaurantBeaconView Image={logo} />
              </CustomDefaultTemplate>
            </RestaurantBeaconViewProvider>
          </Route>
          <Route exact path="/coupon">
            <RestaurantCouponViewProvider>
              <CustomDefaultTemplate>
                <RestaurantCouponView />
              </CustomDefaultTemplate>
            </RestaurantCouponViewProvider>
          </Route>
          <Route exact path="/categories">
            <RestaurantCategoriesViewProvider nextPath="/validate">
              <CustomDefaultTemplate>
                <RestaurantCategoriesView />
              </CustomDefaultTemplate>
            </RestaurantCategoriesViewProvider>
          </Route>
          <Route exact path="/category/:id">
            <RestaurantCategoryViewProvider nextPath="/validate" displayDescription={false}>
              <CustomDefaultTemplate showCategoriesHeader={true}>
                <RestaurantCategoryView />
              </CustomDefaultTemplate>
            </RestaurantCategoryViewProvider>
          </Route>
          <Route
            exact
            path={['/category/:categoryId/product/:id', '/product/:id', '/product/:id/cart/:uid']}
          >
            <RestaurantProductViewProvider>
              <CustomDefaultTemplate>
                <RestaurantProductView />
              </CustomDefaultTemplate>
            </RestaurantProductViewProvider>
          </Route>
          <Route
            exact
            path={[
              '/product/:productId/combo/:filterId',
              '/product/:productId/combo/:filterId/cart/:uid',
            ]}
          >
            <RestaurantProductComboViewProvider nextPath="/validate" basketCountPath="/validate">
              <CustomDefaultTemplate showCategoriesHeader={true}>
                <RestaurantProductComboView />
              </CustomDefaultTemplate>
            </RestaurantProductComboViewProvider>
          </Route>
          <Route exact path="/product/:productId/composed">
            <RestaurantProductComposedViewProvider nextPath="/validate" basketCountPath="/validate">
              <CustomDefaultTemplate showCategoriesHeader={true}>
                <RestaurantProductComposedView />
              </CustomDefaultTemplate>
            </RestaurantProductComposedViewProvider>
          </Route>
          <Route exact path="/validate">
            <RestaurantOrderValidationViewProvider>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={false}
                templateOverride={true}
              >
                <RestaurantOrderValidationView
                  image={<Logo data-test="logo" />}
                  isLogoEnabled={true}
                />
              </CustomDefaultTemplate>
            </RestaurantOrderValidationViewProvider>
          </Route>
          <Route exact path="/cart">
            <RestaurantCartViewProvider showCategoriesHeader={true} upsellEnabled={true}>
              <CustomDefaultTemplate>
                <RestaurantCartView />
              </CustomDefaultTemplate>
            </RestaurantCartViewProvider>
          </Route>
          <Route exact path="/payment-options">
            <PaymentOptionsViewProvider>
              <CustomDefaultTemplate headerEnabled={false} footerEnabled={true}>
                <PaymentOptionsView />
              </CustomDefaultTemplate>
            </PaymentOptionsViewProvider>
          </Route>
          <Route exact path="/payment">
            <CardPaymentViewProvider>
              <CustomDefaultTemplate
                timeoutEnabled={false}
                headerEnabled={false}
                footerEnabled={false}
                templateOverride={true}
              >
                <CardPaymentView />
              </CustomDefaultTemplate>
            </CardPaymentViewProvider>
          </Route>
          <Route exact path="/refund">
            <RefundViewProvider nextPath="/reset">
              <CustomDefaultTemplate
                timeoutEnabled={false}
                headerEnabled={false}
                footerEnabled={false}
                templateOverride={true}
              >
                <RefundView />
              </CustomDefaultTemplate>
            </RefundViewProvider>
          </Route>
          <Route exact path="/checkout">
            <RestaurantOrderConfirmationViewProvider nextPath="/receipt">
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={false}
                templateOverride={true}
              >
                <RestaurantOrderConfirmationView
                  image={<Logo data-test="logo" />}
                  isLogoEnabled={true}
                />
              </CustomDefaultTemplate>
            </RestaurantOrderConfirmationViewProvider>
          </Route>
          <Route exact path="/receipt">
            <ReceiptViewProvider>
              <CustomDefaultTemplate headerEnabled={false} footerEnabled={true}>
                <ReceiptView />
              </CustomDefaultTemplate>
            </ReceiptViewProvider>
          </Route>
          <Route exact path="/admin">
            <AdminViewProvider version={version}>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                showAccessibilityButton={false}
              >
                <AdminView />
              </CustomDefaultTemplate>
            </AdminViewProvider>
          </Route>
          <Route exact path="/notify">
            <NotificationProvider>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={false}
                customBackgroundImage={'bg-black'}
              >
                <NotificationView />
              </CustomDefaultTemplate>
            </NotificationProvider>
          </Route>
          <Route exact path="/auth">
            <AdminAuthenticateProvider>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={true}
                showAccessibilityButton={false}
              >
                <AdminAuthenticateView />
              </CustomDefaultTemplate>
            </AdminAuthenticateProvider>
          </Route>
          <Route exact path="/lock">
            <RestaurantLockViewProvider>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                showAccessibilityButton={false}
                accessibilityEnabled={false}
                timeoutEnabled={false}
              >
                <RestaurantLockView />
              </CustomDefaultTemplate>
            </RestaurantLockViewProvider>
          </Route>
          <SmsViewProvider exact path="/sms" isLogoEnabled={true} isSubtitleEnabled={false}>
            <CustomDefaultTemplate
              headerEnabled={false}
              footerEnabled={false}
              customBackgroundImage={'bg-black'}
            >
              <SmsView />
            </CustomDefaultTemplate>
          </SmsViewProvider>
          <Route exact path="/transactions">
            <TransactionsViewProvider>
              <CustomDefaultTemplate headerEnabled={false} footerEnabled={false}>
                <TransactionsView />
              </CustomDefaultTemplate>
            </TransactionsViewProvider>
          </Route>
          <Route exact path="/loyalty-login">
            <LoyaltyLoginViewProvider>
              <CustomDefaultTemplate headerEnabled={true} footerEnabled={true}>
                <LoyaltyLoginView />
              </CustomDefaultTemplate>
            </LoyaltyLoginViewProvider>
          </Route>
          <Route exact path="/loyalty-offers">
            <LoyaltyOffersViewProvider nextPath="/validate">
              <CustomDefaultTemplate headerEnabled={true} footerEnabled={true}>
                <LoyaltyOffersView />
              </CustomDefaultTemplate>
            </LoyaltyOffersViewProvider>
          </Route>
          {/* invalid route should be the last one. all new routes should be placed above ↑ */}
          <Route>
            <InvalidRouteViewProvider>
              <InvalidRouteView />
            </InvalidRouteViewProvider>
          </Route>
        </Switch>
      </RootViewProvider>
    </Router>
  );
};

export { Routes };
