import React from 'react';

const ArrowButton = ({
  className = 'capitalize',
  onClick = () => {},
  disabled = false,
  isArrowInverse = false,
  'data-test': dataTest = 'button-primary',
}) => {
  return (
    <button
      disabled={disabled}
      className={`inline-flex self-center items-center justify-center w-32 h-20 text-4xl font-bold text-white bg-black rounded-full arrow-header ${className}`}
      onClick={onClick}
      data-test={dataTest}
    >
      {isArrowInverse ? '<' : '>'}
    </button>
  );
};

export { ArrowButton };
