import React, { useMemo } from 'react';

import {
  PrimaryButton,
  BannerWarning,
  LocaleSelection,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import PHIcon from '../../../assets/images/PizzaHutLogo.png';
import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultBackgroundImage from '../../../assets/images/PizzaHutBG.jpg';

const PrimaryHome = ({
  t = () => {},
  healthMsg = true,
  languages = [],
  isCurrentLang,
  primaryAction,
  secondaryAction,
  onChangeLanguage = () => {},
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const secondaryActionClass = useMemo(() => {
    return isCurrentLang('uae') ? 'text-button-large' : 'text-button-large px-20';
  }, [isCurrentLang]);

  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackgroundImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="primary-home"
        className="z-10 flex flex-col items-center justify-center w-full h-full"
      >
        <div className="w-2/5">
          <img src={PHIcon} data-test="home-banner-logo" />
        </div>

        <div
          data-test="home-upper"
          className="absolute bottom-0 flex items-center justify-center w-full mb-32 space-x-4 sm:space-x-12 top-20 md:top-auto md:mb-144"
        >
          {!!primaryAction && (
            <PrimaryButton
              onClick={primaryAction}
              className="px-20 py-6 w-45 sm:w-2/5 text-button-large home-primary-button"
            >
              {t('homePrimaryAction')}
            </PrimaryButton>
          )}

          {!!secondaryAction && (
            <PrimaryButton
              onClick={secondaryAction}
              className={`w-45 sm:w-2/5 py-6 ${secondaryActionClass} home-primary-button`}
              data-test="button-secondary"
            >
              {t('homeSecondaryAction')}
            </PrimaryButton>
          )}
        </div>

        <div
          className="flex flex-col-reverse items-center justify-start w-full h-full"
          data-test="home-lower"
        >
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-terms"
              className={`w-4/5 mb-48`}
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              data-test="banner-health"
              t={t}
              width="109"
              image={WashHandsImage}
              className={`w-4/5 mb-${showTermsAndConditionsButton ? '8' : '48'}`}
            />
          )}
        </div>

        <div
          className="absolute bottom-0 flex flex-col items-center justify-center w-full mb-12 space-y-8"
          data-test="home-footer"
        >
          <p className="text-4xl tracking-wide text-white md:text-5xl">
            {t('languageSelectionTitle')}
          </p>

          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
